@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* print.css */
@media print {
  body {
    margin: 0;
    padding: 0;
    background: white;
    color: black !important;
  }

  #invoice-content {
    width: 100%;
    padding: 20px;
    border: none;
    background: white;
    box-shadow: none;
    color: black !important;
  }

  .item-name {
    color: black !important;
  }

  .no-print {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .why-package-text {
  color: white;
} */

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #3eb555;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  color: black;

  /* margin-top: 40px; */
}

.paystack-button:hover {
  background-color: #359c4a;
}

/* Add this CSS to your global styles or specific component styles */

.scroll-area {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f0f0f0; /* For Firefox: thumb color and track color */
}

.scroll-area::-webkit-scrollbar {
  width: 8px; /* Set width of the vertical scrollbar */
  height: 8px; /* Set height of the horizontal scrollbar */
}

.scroll-area::-webkit-scrollbar-thumb {
  background-color: #888; /* Set color of the thumb */
  border-radius: 4px; /* Optional: rounded edges */
}

.scroll-area::-webkit-scrollbar-track {
  background: #f0f0f0; /* Set color of the track */
}
